import { NavLink } from "react-router-dom";


const AboutUs = () => {
    return (
        <div className="page-content">
            <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                            About Us
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container about">
                    <div className="row">
                        <div className="about-image-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-image-col">
                                <div className="image-color-layer"></div>
                                <div className="title">ABOUT US</div>
                                <div className="image">
                                    <div className="color-layer-2"></div>
                                    <div className="color-layer-3"></div>
                                    <img src="./assets/images/about.jpg" alt="" />
                                </div>
                                <div className="exprience">
                                    <div className="exp-inner">
                                        <span className="exp-number">20</span>
                                        YEARS OF <br/>EXPRIENCES
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-col-content col-lg-6 col-md-12 col-sm-12">
                            <div className="content-inner">
                                <p className="color-primary"><strong>About Us</strong></p>
                                <h1 className="primary-heading">We Will Satisfy You By Our <br/>Work Ideas</h1>
                                <p className="text">Home is a dream of every person, building a home is a lifetime achievement for many people at the same time protecting the home for long periods of time also an important thing.</p>
                                <p className="text">At HomeBoq.com we will provide you all the necessary consultancy for protecting your home for long periods of time.</p>
                                <p className="text">At Homeboq.com we will provide you all the necessary techno commercial information for protecting your home</p>
                                <p className="text">At Homeboq we will provide Bill of Quantities with technical specifications for executing works like waterproofing of the building, Repair of the building and issues like tiling etc.</p>
                                <p className="text">HomeBoq.com is the group company of Sandhi Engineers Pvt Ltd which is having expertise in repair and rehabilitation of RCC structures from the last 10 years.</p>
                                <button className="btn btn-primary" type="submit">
                                    <span>Contact Us</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="about-cta bg-fill-primary">
                <div className="container" style={{padding: '50px 0'}}>
                    <div className="d-flex justify-content-between align-items-center">
                    <p className="secondary-heading text-white">Seal your roof, protect your walls, and fortify<br/> your exterior—Book Now with HomeBOQ for expert Service. </p>
                    <NavLink to='/contact-us'><button type="button" class="btn btn-primary hover-white" fdprocessedid="9yn6s"><span>Contact Us</span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"></path></svg></button></NavLink>
                    </div>
                </div>
            </div>
  
            <section id="whychoose">
                <div className="container">
                    <div className="sec-heading">
                        <p className="text color-primary">Why Choose Us ?</p>
                        <h1 className="primary-heading">High Quality Construction Solutions <br/> For Residentials & Industries!</h1>
                    </div>

                    <div className="row gx-5 gy-5">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-gear" viewBox="0 0 16 16">
                                             <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                                           </svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Expertise and Experience</h2>
                                        <p className="text">With years of experience in the waterproofing industry, Home BOQ delivers top-notch solutions tailored to meet your specific needs. Our team of professionals is skilled in handling a wide range of waterproofing challenges, ensuring high-quality service every time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-droplet" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0q.164.544.371 1.038c.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8m.413 1.021A31 31 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
  <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87z"/>
</svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Comprehensive Solutions</h2>
                                        <p className="text">We offer a full range of services, including roof waterproofing, bathroom waterproofing, internal walls waterproofing, and external waterproofing. No matter the issue, we provide tailored solutions that keep your home protected from water damage.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-check" viewBox="0 0 16 16">
  <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"/>
  <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
</svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Premium-Grade Materials</h2>
                                        <p className="text">At Home BOQ, we use only the highest quality waterproofing materials and products that are tested for durability and long-term effectiveness, ensuring your home stays leak-free for years to come.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Customized Approach</h2>
                                        <p className="text"> Every home is different, and so are its waterproofing needs. We assess each property individually and develop a custom plan to ensure maximum protection, whether it’s for your roof, walls, or water tanks.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
</svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Affordable and Transparent Pricing</h2>
                                        <p className="text"> We believe in delivering high-quality services at competitive prices. With Home BOQ, you get transparent pricing with no hidden costs, ensuring you receive the best value for your investment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="why-choose-card">
                                <div className="why-card-inner">
                                    <div className="icon">
                                       <div className="icon-inner">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>
</svg>
                                       </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="secondary-heading">Customer Satisfaction</h2>
                                        <p className="text">Our services come with a satisfaction guarantee, giving you peace of mind. We take pride in our work and ensure that each project is completed to the highest standard, providing long-lasting results you can rely on.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="vision-intro">
                <div className="container">
                    <div className="sec-heading">
                       <p className="text text-white">Our Mission & Vission</p>
                       <h1 className="primary-heading text-white">We Provide Our Service For <br/>Our Customers</h1>
                    </div>
                </div>
            </section>
            <section id="vision">
                <div className="container">
                    <div className="row gx-5 gy-5">
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="mission-col">
                                <div className="mision-icon">
                                    <div className="mission-icon-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                           <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                           <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                           <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="secondary-heading">Our Mission</h2>
                                <p className="text">Adding Value to Everything We Do. Our Mission is to bring new technologies in providing Building Consultancy Services while maintaining Quality, Customer Satisfaction and Faith. We believe in developing long-term relationships with our clients hence we work as a TEAM and endeavour to provide complete satisfaction to the customers through flawless quality and service in our projects</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="mission-col">
                                <div className="mision-icon">
                                    <div className="mission-icon-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                           <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                           <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                           <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="secondary-heading">Our Vision</h2>
                                <p className="text">At Home BOQ, our vision is to become the leading provider of innovative and reliable waterproofing solutions, creating homes and buildings that stand the test of time. We aim to protect every structure we serve, ensuring it remains safe, dry, and resilient against the elements, now and for future generations.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="mission-col">
                                <div className="mision-icon">
                                    <div className="mission-icon-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                           <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                           <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                           <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="secondary-heading">Core Value</h2>
                                <p className="text">Loyal to our employees and dedicated to their Safety. Exceed our client’s expectations with honesty and respect Earn the respect of the Project Team with ethical practices and accountability Committed to our Community.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 colsm-12">
                            <div className="mission-col">
                                <div className="mision-icon">
                                    <div className="mission-icon-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                           <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                           <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                           <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                           <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="secondary-heading">Infrastructure Facilities</h2>
                                <p className="text">Backed by a team of experienced and dynamic personnel having in-depth understanding about the domain, HomeBoq.com is the trusted name in the building maintenance Consultancy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="customer">
                <div className="container">
                    <div className="sec-heading">
                      <p className="text text-primary">Our Customer</p>
                      <h1 className="primary-heading">We Have Buid Trust<br/>With Our Customer</h1>
                    </div>
                    <div className="row gx-0 gy-0 clients">
                        <div className="col-lg-4 col-sm-6 client">
                            <img src="./assets/images/sampoorji.png" alt="" />
                        </div>
                        <div className="col-lg-4 col-sm-6 client">
                            <img src="./assets/images/tata.jpeg" alt="" />
                        </div>
                        <div className="col-lg-4 col-sm-6 client">
                            <img src="./assets/images/lnt.png" alt="" />
                        </div>
                        <div className="col-lg-4 col-sm-6 client">
                            <img src="./assets/images/aparna.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-sm-6 client2" style={{background: '#ebebeb'}}>
                        <img src="./assets/images/vasavi.png" alt="" />
                        </div>
                        <div className="col-lg-4 col-sm-6 client2" style={{background: '#fff'}}>
                        <img src="./assets/images/myhome.jpg" alt="" />
                           
                        </div>
                       
                        {/* <div className="col-3 client2">
                            <img src="./assets/images/team/3.png" alt="" />
                        </div>
                        <div className="col-3 client2">
                            <img src="./assets/images/team/4.png" alt="" />
                        </div> */}
                    </div>
                </div>

            </section>

        </div>
    )
}

export default AboutUs;