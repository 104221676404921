import { NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext, AuthDispatchContext } from "../context/AuthContext";
import { logout } from "../http";

const Header = () => {

  const auth = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCalc1, setShowCalc1] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
       try {
        const {data} = await logout();
        dispatch({type: 'logout'})
        //window.location.reload()
       } catch (error) {
        console.log(error)
       }
  }

    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary" style={{background: '#fff'}}>
  <div className="container">
    <NavLink to='/'><img className="navbar-brand" style={{height: '80px', width: 'auto'}} src="/logoc.png" /></NavLink>
    <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`} id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li onClick={closeMenu} className="nav-item">
          <NavLink className="nav-link" aria-current="page" to={'/'}>Home</NavLink>
        </li>
        <li onClick={closeMenu} className="nav-item">
          <NavLink className="nav-link" to={'/about-us'}>About Us</NavLink>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </a>
          <ul className="dropdown-menu">
            <li onClick={closeMenu}><NavLink to={'/services/waterproofing-underconstruction-building'} className="dropdown-item">Waterproofing Underconstruction Building</NavLink></li>
            <li onClick={closeMenu}><NavLink to={'/services/waterproofing-old-building'} className="dropdown-item">Waterproofing Old Building</NavLink></li>
            <li onClick={closeMenu}><NavLink to={'/services/repair-of-the-old-building'} className="dropdown-item">Repair of the Old Building</NavLink></li>
            <li onClick={closeMenu}><NavLink to={'/services/tiling-and-epoxy-flooring-solutions'} className="dropdown-item">Tiling and Epoxy Flooring Solutions</NavLink></li>
            
          </ul>
        </li>
        <li onClick={closeMenu} className="nav-item">
          <NavLink className="nav-link" to='/posts'>Blog</NavLink>
        </li>
        <li onClick={closeMenu} className="nav-item">
          <NavLink className="nav-link" to={'/contact-us'}>Contact Us</NavLink>
        </li>
      </ul>
      <div style={{display: 'flex'}}>

        {!auth.name ? <NavLink onClick={closeMenu} style={{marginRight:'20px'}} to='/login'>
        <button type="button" class="btn  btn-outline-primary">
                            <span>Login Here</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </button>
        </NavLink> : (
          <div class="dropdown" style={{marginRight: '20px'}}>
          <button onClick={closeMenu} class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Welcome {auth.name}
          </button>
          <ul class="dropdown-menu">
            <li onClick={closeMenu}><a class="dropdown-item"><NavLink class="dropdown-item" to="/my-booking">My Booking</NavLink></a></li>
            <li onClick={closeMenu}><a class="dropdown-item" onClick={handleLogout} style={{cursor: 'pointer'}}>Logout</a></li>
          </ul>
        </div>
        )}

      <NavLink onClick={closeMenu} to='/book-now'><button className="btn btn-primary" type="submit">
        <span>Book Now</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
</svg>

    </button></NavLink>
      </div>
    </div>
  </div>
</nav>
    )
}

export default Header;